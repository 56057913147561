import styled from '@emotion/styled'
import { Accordion } from '@platform-ui/components/Accordion'
import { Headline32 } from '@platform-ui/typography'
import { SplitedCodeInputs } from '@platform-ui/components/SplitedCodeInputs'
import { ReSendCode } from '../ReSendCode'

export const Root = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '24px 16px',
  minHeight: '640px',

  [`${theme.media.md}`]: {
    padding: '64px 48px 40px 48px',
  },
}))

export const Main = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 'auto',
  marginBottom: 'auto',
  [`${theme.media.md}`]: {
    marginTop: '0px',
  },
}))

export const Title = styled(Headline32)(({ theme }) => ({
  marginTop: '8px',
  textAlign: 'center',
  width: '100%',
  [`${theme.media.md}`]: {
    marginTop: '16px',
  },
}))

export const SplitedCode = styled(SplitedCodeInputs)(() => ({
  marginTop: '24px',
  justifyContent: 'center',
  display: 'flex',
}))

export const Message = styled(Accordion)(({ theme }) => ({
  ...theme.typography['body/14'],
  marginTop: '24px',
  textAlign: 'center',

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
    marginTop: '24px',
  },
}))

export const ErrorText = styled.div(({ theme }) => ({
  color: theme.color['text/error'],
}))

export const ButtonReSendSms = styled(ReSendCode)(({ theme }) => ({
  marginTop: '24px',
  maxWidth: '290px',

  [`${theme.media.md}`]: {
    marginTop: '32px',
  },
}))

export const TextNoWrap = styled.span(() => ({
  whiteSpace: 'nowrap',
}))

export const Footer = styled.div(() => {
  return {
    marginTop: '48px',
  }
})
