import { ApiStatus, useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { PureInputStatus } from '@platform-ui/components/PureInput'
import { useCallback, useEffect, useState } from 'react'
import { ReSendResponseProps, VerifyResponseProps } from './typings'

export const useFormState = (props: { reSendCodeAttempt: number }) => {
  const { eventBus } = useAppContext<AppContext>()

  const [value, setValue] = useState('')

  const [inputStatus, setInputStatus] = useState<PureInputStatus>('default')

  const [resendNum, setResendNum] = useState(props.reSendCodeAttempt)

  const [inputErrorMessage, setInputErrorMessage] = useState('')

  const [reSendCodeResponse, setReSendCodeResponse] = useState<ReSendResponseProps>({
    status: ApiStatus.Idle,
  })

  const [verifyCodeResponse, setVerifyCodeResponse] = useState<VerifyResponseProps>({
    status: ApiStatus.Idle,
  })

  useEffect(() => {
    const reSendRequestUnsub = eventBus.verifyModal.reSendResponse.on((props) => {
      setReSendCodeResponse(props)

      if (props.status === ApiStatus.Fulfilled) {
        setResendNum(props.data.resendNum)
      }
    })

    const verfifyRequestUnsub = eventBus.verifyModal.verfifyResponse.on((props) => {
      setVerifyCodeResponse(props)
    })

    return () => {
      reSendRequestUnsub()
      verfifyRequestUnsub()
    }
  }, [eventBus])

  useEffect(() => {
    if (verifyCodeResponse.status === ApiStatus.Rejected) {
      setInputStatus('error')
      return
    }
    if (verifyCodeResponse.status === ApiStatus.Fulfilled) {
      /**
       * Можно добавить анимации
       */
      eventBus.verifyModal.close.emit({ action: 'success' })
    }
  }, [verifyCodeResponse, eventBus])

  const handleFullfilled = useCallback(
    (value: string) => {
      setInputStatus('success')
      setVerifyCodeResponse({ status: ApiStatus.Pending })
      eventBus.verifyModal.verfifyRequest.emit({ codeValue: value })
    },
    [eventBus, setInputStatus]
  )

  const onChange = useCallback((value: string) => {
    setInputStatus('default')
    setInputErrorMessage('')
    setValue(value.trim())
  }, [])

  const handleSubmit = useCallback(() => {
    if (value.length !== 4) {
      setInputErrorMessage('Код должен состоять из 4-х цифр')
      setInputStatus('error')
      return
    }
    handleFullfilled(value)
  }, [handleFullfilled, value])

  const handleReSendCode = useCallback(() => {
    setReSendCodeResponse({ status: ApiStatus.Pending })
    eventBus.verifyModal.reSendRequest.emit()
  }, [eventBus])

  return {
    verifyCodeResponse,
    reSendCodeResponse,
    inputStatus,
    handleFullfilled,
    onChange,
    inputErrorMessage,
    handleSubmit,
    handleReSendCode,
    value,
    resendNum,
  }
}
