import React, { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { Button } from '@platform-ui/components/Button'
import { observer } from 'mobx-react-lite'
import { useEvent, useUpdateEffect } from '@platform-ui/utils'
import { ReSendCodeStore } from './ReSendCodeStore'

const ButtonReSendSms = styled(Button)(({ theme }) => ({
  marginTop: '24px',

  [`${theme.media.md}`]: {
    marginTop: '32px',
  },
}))

const Message = styled.div(({ theme }) => ({
  background: theme.color['surface/tertiary'],
  borderRadius: '12px',
  width: '100%',
  padding: '16px',
  textAlign: 'center',
}))

export interface ReSendCodeProps {
  className?: string
  timeWaitSec: number
  attempt: number
  isLoading?: boolean
  onSubmit: () => void
}
export const ReSendCode = observer<ReSendCodeProps>((props) => {
  const { attempt, timeWaitSec, onSubmit, isLoading = false, ...otherProps } = props
  const smsCodeStore = useMemo(() => new ReSendCodeStore(), [])

  useEffect(() => {
    smsCodeStore.init({
      attempt: attempt,
      timeWaitSec: timeWaitSec,
    })
  }, [attempt, timeWaitSec, smsCodeStore])

  useEffect(() => {
    return () => {
      smsCodeStore.destroy()
    }
  }, [smsCodeStore])

  useUpdateEffect(() => {
    smsCodeStore.setAttempt(attempt)
  }, [attempt])

  useUpdateEffect(() => {
    smsCodeStore.setTimeWaitSec(timeWaitSec)
  }, [timeWaitSec])

  const handleClick = useEvent(() => {
    onSubmit()
  })

  if (smsCodeStore.isOverAttempt) {
    // TODO: Подумать на текстом ошибки при отсутствии кол-ва попыток
    return (
      <Message {...otherProps}>
        Попытки отправки кода закончились. Попробуйте вернуться в начало и повторить запрос кода.
      </Message>
    )
  }

  return (
    <ButtonReSendSms
      {...otherProps}
      isFullWidth
      size="m"
      isDisabled={!smsCodeStore.timerWaitIsOver}
      view="secondary"
      isLoading={isLoading}
      onClick={handleClick}
    >
      {smsCodeStore.timerWaitIsOver
        ? 'Повторно запросить код'
        : `Повторно запросить код через ${smsCodeStore.timeWaitString}`}
    </ButtonReSendSms>
  )
})
