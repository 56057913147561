import React from 'react'
import { SMSCodeForm } from '../SMSCodeForm'
import { observer } from 'mobx-react-lite'
import { BaseModal } from '@platform-ui/components'

export type VerifyProps = {
  onClose: () => void
  onClosed: () => void
  isOpen: boolean
  maskedPhoneNumber: string
  reSendCodeAttempt?: number
  reSendCodeTimeWaitSec?: number
}

export const Verify = observer<VerifyProps>((props) => {
  const { isOpen, onClose, onClosed, ...otherProps } = props

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} title="">
      <SMSCodeForm {...otherProps} />
    </BaseModal>
  )
})
