import React from 'react'
import { observer } from 'mobx-react-lite'
import { ApiStatus } from '@/core'
import {
  ButtonReSendSms as ReSendCodeButton,
  Title,
  Message,
  SplitedCode,
  TextNoWrap,
  Root,
  Footer,
  Main,
  ErrorText,
} from './styled'
import { Button } from '@platform-ui/components/Button'
import { useFormState } from './useFormState'

export interface SMSCodeFormProps {
  maskedPhoneNumber: string
  reSendCodeAttempt?: number
  reSendCodeTimeWaitSec?: number
}

export const SMSCodeForm: React.FC<SMSCodeFormProps> = observer(
  ({ maskedPhoneNumber, reSendCodeAttempt = 3, reSendCodeTimeWaitSec = 30 }) => {
    const {
      handleFullfilled,
      handleReSendCode,
      handleSubmit,
      reSendCodeResponse,
      onChange,
      value,
      inputStatus,
      verifyCodeResponse,
      inputErrorMessage,
      resendNum,
    } = useFormState({ reSendCodeAttempt })
    return (
      <Root>
        <Main>
          <Title typographySm="headline/24">Введите СМС-код</Title>

          <SplitedCode
            length={4}
            value={value}
            onChange={onChange}
            onFullfilled={handleFullfilled}
            isReadOnly={
              verifyCodeResponse.status === ApiStatus.Pending ||
              verifyCodeResponse.status === ApiStatus.Fulfilled
            }
            status={inputStatus}
            isAutoFocus
          />
          <Message isOpen={true}>
            {inputErrorMessage ? (
              <ErrorText>{inputErrorMessage}</ErrorText>
            ) : verifyCodeResponse.status === ApiStatus.Idle ? (
              <div>
                Мы выслали код на номер <br />
                <TextNoWrap>{maskedPhoneNumber}</TextNoWrap>
              </div>
            ) : (
              verifyCodeResponse.status === ApiStatus.Rejected && (
                <ErrorText>{verifyCodeResponse.error.message}</ErrorText>
              )
            )}
          </Message>

          <ReSendCodeButton
            attempt={resendNum}
            timeWaitSec={reSendCodeTimeWaitSec}
            isLoading={reSendCodeResponse.status === ApiStatus.Pending}
            onSubmit={handleReSendCode}
          />
          {reSendCodeResponse.status === ApiStatus.Rejected && (
            <ErrorText>При повторной отправке произошла ошибка</ErrorText>
          )}
        </Main>
        <Footer>
          <Button
            isFullWidth
            onClick={handleSubmit}
            isLoading={verifyCodeResponse.status === ApiStatus.Pending}
          >
            Продолжить
          </Button>
        </Footer>
      </Root>
    )
  }
)
